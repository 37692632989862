import React, { FC, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Select,
  MenuItem,
  TextField,
  Button,
  Box
} from "@mui/material";
import dayjs from "dayjs";
import { ALLOCATION_TYPES, useCreateAllocationRequestMutation, useLazyGetInvestmentRequestAllocationByGameIdQuery, useLazyGetInvestmentRequestInvestorFiltersQuery, useRejectInvestmentRequestMutation } from "@api/investments";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Loader } from "@components";

interface FundingAllocationProps {
  rowData: any;
  setCloseUpdate: () => void;
  gameId: string;
}

const fundingTypeOptions = [
  { label: "Financing (Funding Refund)", value: "FINANCING_FUNDING_REFUND" },
  { label: "Financing (Payments)", value: "FINANCING_PAYMENTS" },
  { label: "Fresh Financing", value: "FRESH_FINANCING" },
  { label: "Re-Financing", value: "RE_FINANCING" },
  { label: "Funding Refund", value: "FUNDING_REFUND" },
  { label: "Funding Top Up", value: "FUNDING_TOP_UP" },
  { label: "Payments", value: "PAYMENTS" },
  { label: "Collection", value: "Revenue Share" },

];

const FundingAllocation: FC<FundingAllocationProps> = (props) => {
  const { rowData, setCloseUpdate, gameId } = props;

  const [games, setGames] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [getInvestmentRequestAllocationByGameId] = useLazyGetInvestmentRequestAllocationByGameIdQuery();
  const [getInvestmentRequestInvestorFilters] = useLazyGetInvestmentRequestInvestorFiltersQuery();
  const [rejectInvestmentRequest] = useRejectInvestmentRequestMutation();
  const [createAllocationRequest] = useCreateAllocationRequestMutation();

  useEffect(() => {
    const getRequests = async () => {
      setLoading(true);
      const games = await getInvestmentRequestInvestorFilters({ gameId: gameId }).unwrap();
      if (games?.data?.length) {
        setGames(games.data.map((g: any) => ({ label: g, value: g })))
      }

      const res = await getInvestmentRequestAllocationByGameId({ requestId: rowData.id }).unwrap();
      if (res?.data?.length) {
        const isAdjustment = res.data.some((investment: any) => investment.allocationType === ALLOCATION_TYPES.ADJUSTMENT);
        const data = res.data.map((allocation: any) => ({
          investor: allocation.investor,
          type: allocation.investmentType,
          amount: allocation.amount,
          date: dayjs(allocation.settlementDate),
          allocationType: allocation.allocationType,
          disabled: isAdjustment && allocation.allocationType === ALLOCATION_TYPES.ALLOCATION
        }));
        setRows(data);
        setLoading(false);
      }
    };

    if (rowData.id) {
      getRequests();
    }
  }, []);

  const handleRowChange = (index: number, field: string, value: any) => {
    const updatedRows = [...rows];
    (updatedRows[index] as any)[field] = value;
    setRows(updatedRows);
  };

  const handleRejectRequest = () => {
    if (rowData.id) {
      rejectInvestmentRequest({ requestId: rowData.id });
      setCloseUpdate();
    }
  };

  const handleCreateAllocation = () => {
    if (rowData.id && rows.every(obj =>
      obj.investor &&
      obj.type &&
      obj.amount > 0
    )) {
      const isAdjustment = rows.some((investment: any) => investment.allocationType === ALLOCATION_TYPES.ADJUSTMENT);
      const filteredRows = isAdjustment
        ? rows.filter((row: any) => row.allocationType === ALLOCATION_TYPES.ADJUSTMENT)
        : rows;
      const allocations = filteredRows.map((row: any) => ({
        investor: row.investor,
        investmentType: row.type,
        amount: row.amount,
        settlementDate: dayjs(row.date).toISOString().slice(0, 10)
      }));

      const payload = {
        requestId: rowData.id,
        allocations: allocations
      }
      createAllocationRequest(payload);
      setCloseUpdate();
    }
  };

  const formatCurrency = (value: any) => {
    const formattedValue = Math.round(Math.abs(Number(value))).toLocaleString(
      "en-US"
    );
    return value < 0 ? `- $${formattedValue}` : `$${formattedValue}`;
  };

  const handleCreateNewRow = () => {
    if (rows.length) {
      const lastRow = rows[rows.length - 1];
      if (!lastRow.investor || !lastRow.type || !lastRow.amount) {
        return;
      }
      const newRow = {
        investor: "",
        type: "",
        amount: 0,
        date: (rows[rows.length - 1] as any).date,
        disabled: false
      }

      setRows([...rows, newRow]);
    }
  };

  const handleDeleteRow = (index: number) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  }

  return (
    <TableContainer sx={{ height: "100%", width: "100%", borderRadius: "16px", p: 0, pb: "1rem" }}>
      <Table>
        <TableBody>
          {isLoading ? (
            <TableRow key="table_loader">
              <TableCell colSpan={14} sx={{ borderBottom: "none" }}>
                <Box sx={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>
                  <Loader customWidth="3rem" />
                </Box>
              </TableCell>
            </TableRow>
          ) : (
            rows.map((row: any, index) => (
              <TableRow
                key={index}
                sx={{
                  opacity: row.disabled ? 0.5 : 1,
                  pointerEvents: row.disabled ? "none" : "auto"
                }}
              >
                <TableCell
                  colSpan={14}
                  sx={{
                    pt: index === 0 ? "1rem" : "0.25rem",
                    pb: index === rows.length - 1 ? "1rem" : "0.25rem",
                    borderBottom: "none"
                  }}
                >
                  <Box sx={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>
                    <Box sx={{
                      width: "100%",
                      display: "flex",
                      border: "2px solid #9AD6D1",
                      borderRadius: "1rem",
                      marginLeft: "2rem",
                      marginRight: "2rem",
                      px: "1rem",
                      alignItems: "center",
                      background: "#FFFFFF"
                    }}>
                      <Box sx={{ flex: 1, borderRight: "1px solid #2F736E1F" }}>
                        <Select
                          value={row.investor}
                          onChange={(e) => handleRowChange(index, "investor", e.target.value)}
                          fullWidth
                          sx={{
                            height: "3rem",
                            p: 0,
                            border: "none",
                            "& fieldset": {
                              border: "none"
                            }
                          }}
                        >
                          {games.map((g) => (
                            <MenuItem value={g.value}>{g.label}</MenuItem>
                          ))}
                        </Select>
                      </Box>
                      <Box sx={{ flex: 1, borderRight: "1px solid #2F736E1F" }}>
                        <Select
                          value={row.type}
                          onChange={(e) => handleRowChange(index, "type", e.target.value)}
                          fullWidth
                          sx={{
                            height: "3rem",
                            p: 0,
                            border: "none",
                            "& fieldset": {
                              border: "none"
                            }
                          }}
                        >
                          {fundingTypeOptions.map((type) => (
                            <MenuItem value={type.value}>{type.label}</MenuItem>
                          ))}
                        </Select>
                      </Box>
                      <Box sx={{ flex: 1, borderRight: "1px solid #2F736E1F" }}>
                        <TextField
                          type="text"
                          value={formatCurrency(row.amount)}
                          onChange={(e) => {
                            const value = e.target.value.replace(/[\$,]/g, '');
                            handleRowChange(index, "amount", Number(value))
                          }}
                          fullWidth
                          inputProps={{ style: { textAlign: "right" } }}
                          sx={{
                            "& fieldset": { border: "none" },
                          }}
                        />
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        <TextField
                          type="text"
                          value={dayjs(row.date).format("DD/MM/YYYY")}
                          onChange={(e) => null}
                          fullWidth
                          inputProps={{ style: { textAlign: "right" } }}
                          sx={{
                            "& fieldset": { border: "none" },
                          }}
                        />
                      </Box>
                    </Box>
                    <DeleteOutlineIcon
                      htmlColor="#9AD6D1"
                      sx={{
                        height: '1.5rem',
                        width: '1.5rem',
                        cursor: "pointer",
                        backgroundColor: "#E6EEED",
                      }}
                      onClick={() => handleDeleteRow(index)}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            ))
          )}
          <TableRow
            key={'add_investor_action_button'}
          >
            <TableCell
              colSpan={14}
              sx={{
                pt: "0.25rem",
                pb: "1rem",
                pl: "48px",
                borderBottom: "none"
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  color: "#51B8B0",
                  border: "none",
                  textTransform: "none",
                  '&:hover': {
                    border: "none",
                  }
                }}
                onClick={() => handleCreateNewRow()}
              >
                + Add Investor
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 8,
          padding: "0px 48px",
        }}
      >
        <Box flex={1}>
          <Button
            variant="contained"
            color="primary"
            sx={{
              mr: "1rem",
              borderRadius: "2rem",
              textTransform: "none"
            }}
            onClick={() => handleCreateAllocation()}
          >
            Confirm
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            sx={{
              borderRadius: "2rem",
              textTransform: "none"
            }}
            onClick={() => setCloseUpdate()}
          >
            Cancel
          </Button>
        </Box>
        <Button
          variant="outlined"
          color="error"
          sx={{
            border: "solid 1px",
            borderRadius: "2rem",
            textTransform: "none",
            fontWeight: 600
          }}
          onClick={() => handleRejectRequest()}
        >
          Reject Request
        </Button>
      </div>
    </TableContainer >
  );
};

export default FundingAllocation;

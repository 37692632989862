import React, { useEffect, useState } from "react";
import { FullPageLoader } from "@components";
import BenchmarkingTable from "../components/Benchmarks/BenchmarkingTable";
import BenchmarkingFilters from "../components/Benchmarks/BenchmarkingFilters";
import { byChannelDataM1, byChannelDataM2, marketinSpendDataM1, marketinSpendDataM2, metricsData, roasRowData } from "./data";

export type AttributesData = {
  label: string;
  value: string;
  color: string;
  border: string;
};

export type RowDataItem = {
  label: string;
  value: string;
  icon?: string;
  vsCategory: AttributesData[];
  vsGenre: AttributesData[];
};

export type RowData = {
  header: string;
  data: RowDataItem[];
};



const Benchmarks: React.FC = () => {
  const [dashboardLoader, setDashboardLoader] = useState<boolean>(true);
  const [selectedChannelRoas, setSelectedChannelRoas] = useState<string>("M1");
  const [selectedMarketingRoas, setSelectedMarketingRoas] = useState<string>("M1");
  const [byChannelData, setByChannelData] = useState(byChannelDataM1);
  const [byMarketingSpendData, setByMarketingSpendData] = useState(marketinSpendDataM1);

  useEffect(() => {
    const getByChannelData = () => {
      if (selectedChannelRoas === "M1"){
        setByChannelData(byChannelDataM1)
      }
      else if (selectedChannelRoas === "M2"){
        setByChannelData(byChannelDataM2)
      }
      if (selectedChannelRoas === "M6"){
        setByChannelData(byChannelDataM1)
      }
      else if (selectedChannelRoas === "M12"){
        setByChannelData(byChannelDataM2)
      }
    }
    const updateMarketingSpendData = () => {
      if (selectedMarketingRoas === "M1") {
        setByMarketingSpendData(marketinSpendDataM1);
      } else if (selectedMarketingRoas === "M2") {
        setByMarketingSpendData(marketinSpendDataM2);
      } else if (selectedMarketingRoas === "M6") {
        setByMarketingSpendData(marketinSpendDataM1);
      } else if (selectedMarketingRoas === "M12") {
        setByMarketingSpendData(marketinSpendDataM2);
      }
    };
    getByChannelData()
    updateMarketingSpendData()
  },[selectedChannelRoas, selectedMarketingRoas])

  return (
    <>
      {/* {dashboardLoader && <FullPageLoader hasSideNav={true} />} */}
      <div
        id="pvx-benchmarks-container"
        style={{
          width: "100%",
          height: "100vh",
          border: "none",
          overflow: "auto",
          paddingLeft: "2rem",
          paddingRight: "2rem",
          paddingTop: "1rem",
          paddingBottom: "5rem",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          gap: "1.125rem",
        }}
      >
        <BenchmarkingFilters/>
        <BenchmarkingTable rowData={roasRowData} enableToggleRoas={false}/>
        <BenchmarkingTable rowData={byChannelData} enableToggleRoas={true} setSelectedRoas={setSelectedChannelRoas}/>
        <BenchmarkingTable rowData={byMarketingSpendData} enableToggleRoas={true} setSelectedRoas={setSelectedMarketingRoas}/>
        <BenchmarkingTable rowData={metricsData} enableToggleRoas={false}/>
      </div>
    </>
  );
};

export default Benchmarks;

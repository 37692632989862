import React, { useState } from "react";
import { FullPageLoader } from "@components";
import BenchmarkingTable from "../components/Benchmarks/BenchmarkingTable";

export type AttributesData = {
  label: string;
  value: string;
  color: string;
  border: string;
};

export type RowDataItem = {
  label: string;
  value: string;
  icon: string;
  vsCategory: AttributesData[];
  vsGenre: AttributesData[];
};

export type RowData = {
  header: string;
  data: RowDataItem[];
};


const rowData: RowData = {
  header: "Monthly ROAS Projections",
  data: [
    {
      label: "M1 ROAS",
      value: "200%",
      icon:"pvx-icon.svg",
      vsCategory: [
        { label: "P 25", value: "25%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "50%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "75%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "60%", color: "#FF9E2B", border: "#2E4A4F" }
      ],
      vsGenre: [
        { label: "P 25", value: "35%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "45%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "80%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "48%", color: "#34746D", border: "#2E4A4F" }
      ]
    },
    {
      label: "M3 ROAS",
      value: "200%",
      icon:"pvx-icon.svg",
      vsCategory: [
        { label: "P 25", value: "30%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "40%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "65%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "60%", color: "#FF9E2B", border: "#2E4A4F" }
      ],
      vsGenre: [
        { label: "P 25", value: "25%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "35%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "90%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "80%", color: "#FF6646", border: "#2E4A4F" }
      ]
    },
    {
      label: "M6 ROAS",
      value: "200%",
      icon:"pvx-icon.svg",
      vsCategory: [
        { label: "P 25", value: "25%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "30%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "65%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "40%", color: "#FF6646", border: "#2E4A4F" }
      ],
      vsGenre: [
        { label: "P 25", value: "25%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "50%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "70%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "68%", color: "#FF9E2B", border: "#2E4A4F" }
      ]
    },
    {
      label: "M12 ROAS",
      value: "200%",
      icon:"pvx-icon.svg",
      vsCategory: [
        { label: "P 25", value: "35%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "50%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "75%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "78%", color: "#FF9E2B", border: "#2E4A4F" }
      ],
      vsGenre: [
        { label: "P 25", value: "15%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "55%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "70%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "58%", color: "#34746D", border: "#2E4A4F" }
      ]
    }
  ]
};

const Benchmarks: React.FC = () => {
  const [dashboardLoader, setDashboardLoader] = useState<boolean>(true);

  return (
    <>
      {/* {dashboardLoader && <FullPageLoader hasSideNav={true} />} */}
      <div
        id="pvx-benchmarks-container"
        style={{
          width: "100%",
          height: "100vh",
          border: "none",
          overflow: "auto",
          paddingLeft: "2rem",
          paddingRight: "2rem",
          paddingTop: "1rem",
          paddingBottom: "1rem",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          gap: "1.125rem"
        }}
      >
        <BenchmarkingTable rowData={rowData} enableToggleRoas={false}/>
      </div>
    </>
  );
};

export default Benchmarks;

import { FC, useState } from "react";
import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material"
import CheckIcon from '@mui/icons-material/Check';
import { ReactComponent as PVXIcon } from "../../../../assets/icons/pvx-icon.svg";
import { RowData, RowDataItem } from "../../CustomDashboards/Benchmarks";
import BenchmarkingAttributesField from "./BenchmarkingAttributesField";

const headerData = [
  {
    text: "Spends",
    borderRadius: "0.5rem 0rem 0rem 0.5rem",
    borderRight: "1px solid #737F86",
    width: "auto",
  },
  {
    text: "vs Category",
    borderRadius: "0rem",
    borderRight: "1px solid #737F86",
    width: "50%",
  },
  {
    text: "vs Genre",
    borderRadius: "0rem 0.5rem 0.5rem 0rem",
    borderRight: "none",
    width: "50%",
  }
];

const roas = [
  {id: "m1", label: "M1"},
  {id: "m3", label: "M2"},
  {id: "m6", label: "M6"},
  {id: "m12", label: "M12"}
]

type BenchmarkingTableProps = {
  rowData: RowData,
  enableToggleRoas: Boolean,
  setSelectedRoas?: React.Dispatch<React.SetStateAction<string>>,
  selectedRoas?: string
}

const BenchmarkingTable: FC<BenchmarkingTableProps> = ({rowData, enableToggleRoas, setSelectedRoas}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [toggleClick, setToggleClick] = useState<Record<number, boolean>>({ 0: true });

  const handleToggle = (index: number) => {
    setToggleClick((prevState) => {
      const newState = Object.keys(prevState).reduce((acc, key) => {
        acc[Number(key)] = false;
        return acc;
      }, {} as Record<number, boolean>);
      return {
        ...newState,
        [index]: !prevState[index],
      };
    });
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "fit-content",
        borderRadius: "0.75rem",
        backgroundColor: "#FFFFFF",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        padding: "0.75rem",
        position: "relative"
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "0.25rem",
          position: "absolute",
          right: "1.125rem",
          top: isMobile && enableToggleRoas ? "9rem" : "6.813rem"
        }}
      >
        <PVXIcon/>
        <Typography
          sx={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: 500,
            fontSize: "10px",
            color: "#8E979D"
          }}
        >
        PvX Partners
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between"
        }}
      >
        <Typography
          sx={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: 700,
            fontSize: "1rem",
            lineHeight: "1.375rem"
          }}
        >
          {rowData.header}
        </Typography>
        {enableToggleRoas && 
          <Box
            sx={{
              display: "flex",
              gap: "0.5rem",
              paddingTop: isMobile ? "0.5rem" : ""
            }}
          >
            {roas.map((row, index) => (
              <Button
              key={index}
              sx={{
                border: "1px solid #E8EAEB",
                borderRadius: "0.5rem",
                backgroundColor: toggleClick[index] ? "#AECBD0": "",
                lineHeight: "1rem",
                color: toggleClick[index] ? "#162936": "#6C6C6C",
                display: "flex",
                gap: "0.25rem"
              }}
              onClick={() => {handleToggle(index); setSelectedRoas && setSelectedRoas(row.label);}}
            >
              {toggleClick[index] && <CheckIcon sx={{height:"1rem", width: "1rem"}}/>}
              {row.label}
            </Button>
            ))}
          </Box>
        }
      </Box>
      <Box
        sx={{
          display: "flex",
        }}
      >
        {headerData.map((header, index) => (
          <Box
            key={index}
            sx={{
              height: "2.5rem",
              borderRadius: header.borderRadius,
              borderRight: header.borderRight,
              backgroundColor: "#2F736E",
              display: "flex",
              alignItems: "center",
              justifyContent: index === 0 ? "flex-start" : "center",
              padding: "1rem",
              width: header.width
            }}
          >
            <Typography
              sx={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: 700,
                fontSize: "0.75rem",
                lineHeight: "1rem",
                color: "#F6F8F9",
              }}
            >
              {header.text}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.75rem"
        }}
      >
        {rowData.data.map((row: RowDataItem) => (
          <Box>
            <Box
              sx={{
                display: "flex",
                gap: "0.25rem",
                alignItems: "center",
                marginBottom: "0.25rem"
              }}
            >
              {row.icon && <img src={require(`../../../../assets/icons/${row.icon}`)} alt="" style={{width: "1rem"}}/>}
              <Typography
                sx={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: 700,
                  fontSize: "0.75rem",
                  lineHeight: "1rem",
                  color: "#162936",
                }}
              >
                {row.label}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "0.25rem"
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#F0F0F1",
                  width: "4.79rem",
                  height: "4.375rem",
                  border: "2px solid #E8EAEB",
                  borderRadius: "0.5rem",
                  padding: "1rem 0.5rem"
                }}
              >
                <Typography
                    sx={{
                      fontFamily: "Manrope, sans-serif",
                      fontWeight: 700,
                      fontSize: "0.75rem",
                      lineHeight: "1rem",
                      color: "#F6F8F9",
                      backgroundColor: "#2F736E",
                      border: "1px solid #162C36",
                      borderRadius: "0.5rem",
                      padding: "0.5rem 0.75rem"
                    }}
                >
                  {row.value}
                </Typography>
              </Box>
              <BenchmarkingAttributesField boxData={row.vsCategory}/>
              <BenchmarkingAttributesField boxData={row.vsGenre}/>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default BenchmarkingTable;
import { RowData } from "./Benchmarks";

export const roasRowData: RowData = {
  header: "Monthly ROAS Projections",
  data: [
    {
      label: "M1 ROAS",
      value: "200%",
      // icon:"pvx-icon.svg",
      vsCategory: [
        { label: "P 25", value: "25%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "50%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "75%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "60%", color: "#FF9E2B", border: "#2E4A4F" }
      ],
      vsGenre: [
        { label: "P 25", value: "35%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "45%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "80%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "48%", color: "#34746D", border: "#2E4A4F" }
      ],
    },
    {
      label: "M3 ROAS",
      value: "200%",
      // icon:"pvx-icon.svg",
      vsCategory: [
        { label: "P 25", value: "30%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "40%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "65%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "60%", color: "#FF9E2B", border: "#2E4A4F" }
      ],
      vsGenre: [
        { label: "P 25", value: "25%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "35%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "90%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "80%", color: "#FF6646", border: "#2E4A4F" }
      ]
    },
    {
      label: "M6 ROAS",
      value: "200%",
      // icon:"pvx-icon.svg",
      vsCategory: [
        { label: "P 25", value: "25%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "30%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "65%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "40%", color: "#FF6646", border: "#2E4A4F" }
      ],
      vsGenre: [
        { label: "P 25", value: "25%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "50%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "70%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "68%", color: "#FF9E2B", border: "#2E4A4F" }
      ]
    },
    {
      label: "M12 ROAS",
      value: "200%",
      // icon:"pvx-icon.svg",
      vsCategory: [
        { label: "P 25", value: "35%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "50%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "75%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "78%", color: "#FF9E2B", border: "#2E4A4F" }
      ],
      vsGenre: [
        { label: "P 25", value: "15%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "55%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "70%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "58%", color: "#34746D", border: "#2E4A4F" }
      ]
    }
  ]
};

export const byChannelDataM1: RowData = {
  header: "By Channel",
  data: [
    {
      label: "Facebook",
      value: "200%",
      icon:"fb_logo.svg",
      vsCategory: [
        { label: "P 25", value: "25%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "50%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "75%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "60%", color: "#FF9E2B", border: "#2E4A4F" }
      ],
      vsGenre: [
        { label: "P 25", value: "35%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "45%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "80%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "48%", color: "#34746D", border: "#2E4A4F" }
      ]
    },
    {
      label: "Google",
      value: "200%",
      icon:"google_logo.svg",
      vsCategory: [
        { label: "P 25", value: "30%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "40%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "65%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "60%", color: "#FF9E2B", border: "#2E4A4F" }
      ],
      vsGenre: [
        { label: "P 25", value: "25%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "35%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "90%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "80%", color: "#FF6646", border: "#2E4A4F" }
      ]
    },
    {
      label: "Applovin",
      value: "200%",
      icon:"applovin_logo.svg",
      vsCategory: [
        { label: "P 25", value: "25%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "30%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "65%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "40%", color: "#FF6646", border: "#2E4A4F" }
      ],
      vsGenre: [
        { label: "P 25", value: "25%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "50%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "70%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "68%", color: "#FF9E2B", border: "#2E4A4F" }
      ]
    },
    {
      label: "Tiktok",
      value: "200%",
      icon:"tiktok_logo.svg",
      vsCategory: [
        { label: "P 25", value: "35%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "50%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "75%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "78%", color: "#FF9E2B", border: "#2E4A4F" }
      ],
      vsGenre: [
        { label: "P 25", value: "15%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "55%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "70%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "58%", color: "#34746D", border: "#2E4A4F" }
      ]
    }
  ]
};

export const byChannelDataM2: RowData = {
  header: "By Channel",
  data: [
    {
      label: "Facebook",
      value: "180%",
      icon: "fb_logo.svg",
      vsCategory: [
        { label: "P 25", value: "48%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "15%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "48%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "22%", color: "#FF9E2B", border: "#2E4A4F" }
      ],
      vsGenre: [
        { label: "P 25", value: "18%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "27%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "62%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "40%", color: "#34746D", border: "#2E4A4F" }
      ]
    },
    {
      label: "Google",
      value: "190%",
      icon: "google_logo.svg",
      vsCategory: [
        { label: "P 25", value: "23%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "25%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "38%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "23%", color: "#FF9E2B", border: "#2E4A4F" }
      ],
      vsGenre: [
        { label: "P 25", value: "78%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "68%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "42%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "62%", color: "#FF6646", border: "#2E4A4F" }
      ]
    },
    {
      label: "Applovin",
      value: "170%",
      icon: "applovin_logo.svg",
      vsCategory: [
        { label: "P 25", value: "10%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "25%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "58%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "32%", color: "#FF6646", border: "#2E4A4F" }
      ],
      vsGenre: [
        { label: "P 25", value: "10%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "32%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "53%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "70%", color: "#FF9E2B", border: "#2E4A4F" }
      ]
    },
    {
      label: "Tiktok",
      value: "160%",
      icon: "tiktok_logo.svg",
      vsCategory: [
        { label: "P 25", value: "68%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "45%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "88%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "50%", color: "#FF9E2B", border: "#2E4A4F" }
      ],
      vsGenre: [
        { label: "P 25", value: "78%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "28%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "55%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "40%", color: "#34746D", border: "#2E4A4F" }
      ]
    }
  ]
};

export const marketinSpendDataM1: RowData = {
  header: "By Marketing Spend (in USD $)",
  data: [
    {
      label: "<100k",
      value: "200%",
      // icon:"pvx-icon.svg",
      vsCategory: [
        { label: "P 25", value: "25%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "50%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "75%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "60%", color: "#FF9E2B", border: "#2E4A4F" }
      ],
      vsGenre: [
        { label: "P 25", value: "35%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "45%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "80%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "48%", color: "#34746D", border: "#2E4A4F" }
      ]
    },
    {
      label: "100K - 250k",
      value: "200%",
      // icon:"pvx-icon.svg",
      vsCategory: [
        { label: "P 25", value: "30%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "40%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "65%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "60%", color: "#FF9E2B", border: "#2E4A4F" }
      ],
      vsGenre: [
        { label: "P 25", value: "25%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "35%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "90%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "80%", color: "#FF6646", border: "#2E4A4F" }
      ]
    },
    {
      label: "250K - 500k",
      value: "200%",
      // icon:"pvx-icon.svg",
      vsCategory: [
        { label: "P 25", value: "25%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "30%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "65%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "40%", color: "#FF6646", border: "#2E4A4F" }
      ],
      vsGenre: [
        { label: "P 25", value: "25%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "50%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "70%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "68%", color: "#FF9E2B", border: "#2E4A4F" }
      ]
    },
    {
      label: "500K - 1M",
      value: "200%",
      // icon:"pvx-icon.svg",
      vsCategory: [
        { label: "P 25", value: "35%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "50%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "75%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "78%", color: "#FF9E2B", border: "#2E4A4F" }
      ],
      vsGenre: [
        { label: "P 25", value: "15%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "55%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "70%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "58%", color: "#34746D", border: "#2E4A4F" }
      ]
    }
  ]
};

export const marketinSpendDataM2: RowData = {
  header: "By Marketing Spend (in USD $)",
  data: [
    {
      label: "<100k",
      value: "200%",
      // icon:"pvx-icon.svg",
      vsCategory: [
        { label: "P 25", value: "45%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "50%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "85%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "50%", color: "#FF9E2B", border: "#2E4A4F" }
      ],
      vsGenre: [
        { label: "P 25", value: "25%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "55%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "70%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "38%", color: "#34746D", border: "#2E4A4F" }
      ]
    },
    {
      label: "100K - 250k",
      value: "200%",
      // icon:"pvx-icon.svg",
      vsCategory: [
        { label: "P 25", value: "10%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "20%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "45%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "56%", color: "#FF9E2B", border: "#2E4A4F" }
      ],
      vsGenre: [
        { label: "P 25", value: "40%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "55%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "80%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "45%", color: "#FF6646", border: "#2E4A4F" }
      ]
    },
    {
      label: "250K - 500k",
      value: "200%",
      // icon:"pvx-icon.svg",
      vsCategory: [
        { label: "P 25", value: "15%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "30%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "75%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "50%", color: "#FF6646", border: "#2E4A4F" }
      ],
      vsGenre: [
        { label: "P 25", value: "35%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "40%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "60%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "78%", color: "#FF9E2B", border: "#2E4A4F" }
      ]
    },
    {
      label: "500K - 1M",
      value: "200%",
      // icon:"pvx-icon.svg",
      vsCategory: [
        { label: "P 25", value: "35%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "50%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "75%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "78%", color: "#FF9E2B", border: "#2E4A4F" }
      ],
      vsGenre: [
        { label: "P 25", value: "15%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "55%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "70%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "58%", color: "#34746D", border: "#2E4A4F" }
      ]
    }
  ]
};

export const metricsData: RowData = {
  header: "Other Metrics",
  data: [
    {
      label: "M6 Incremental ROAS",
      value: "200%",
      // icon:"pvx-icon.svg",
      vsCategory: [
        { label: "P 25", value: "25%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "50%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "75%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "60%", color: "#FF9E2B", border: "#2E4A4F" }
      ],
      vsGenre: [
        { label: "P 25", value: "35%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "45%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "80%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "48%", color: "#34746D", border: "#2E4A4F" }
      ]
    },
    {
      label: "M6 User Retention",
      value: "200%",
      // icon:"pvx-icon.svg",
      vsCategory: [
        { label: "P 25", value: "30%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "40%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "65%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "60%", color: "#FF9E2B", border: "#2E4A4F" }
      ],
      vsGenre: [
        { label: "P 25", value: "25%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "35%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "90%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "80%", color: "#FF6646", border: "#2E4A4F" }
      ]
    },
    {
      label: "M6 Revenue Retention",
      value: "200%",
      // icon:"pvx-icon.svg",
      vsCategory: [
        { label: "P 25", value: "25%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "30%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "65%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "40%", color: "#FF6646", border: "#2E4A4F" }
      ],
      vsGenre: [
        { label: "P 25", value: "25%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "50%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "70%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "68%", color: "#FF9E2B", border: "#2E4A4F" }
      ]
    },
    {
      label: "Payback Period",
      value: "200%",
      // icon:"pvx-icon.svg",
      vsCategory: [
        { label: "P 25", value: "35%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "50%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "75%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "78%", color: "#FF9E2B", border: "#2E4A4F" }
      ],
      vsGenre: [
        { label: "P 25", value: "15%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "55%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "70%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "58%", color: "#34746D", border: "#2E4A4F" }
      ]
    },
    {
      label: "% Organic Revenue",
      value: "200%",
      // icon:"pvx-icon.svg",
      vsCategory: [
        { label: "P 25", value: "25%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "30%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "65%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "40%", color: "#FF6646", border: "#2E4A4F" }
      ],
      vsGenre: [
        { label: "P 25", value: "25%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 50", value: "50%", color: "#2F736E14", border: "#AECBD0" },
        { label: "P 75", value: "70%", color: "#2F736E14", border: "#AECBD0" },
        { label: "", value: "68%", color: "#FF9E2B", border: "#2E4A4F" }
      ]
    },
  ]
};
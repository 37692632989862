import { Box, Typography } from "@mui/material"
import { FC } from "react";
import { AttributesData } from "../../CustomDashboards/Benchmarks";

const BenchmarkingAttributesField: FC<{boxData: AttributesData[]}> = ({boxData}) => {
  return(
    <Box
      sx={{
        height: "4.375rem",
        width: "100%",
        border: "2px solid #E8EAEB",
        borderRadius: "0.5rem",
        backgroundColor: "#F0F0F1",
        padding: "1rem 1.5rem",
      }}
    >
      <Box
        sx={{
          height: "100%",
          borderRadius: "0.5rem",
          background: "repeating-linear-gradient(135deg, #F0F0F1, #F0F0F1 1px, #E0E0E0 5px, #E0E0E0 1px)",
          position: "relative"
        }}
      >
        {boxData.map((data: AttributesData, index) => (
          <Box
            key={index}
            sx={{
              width: "1.313rem",
              height: "106%",
              backgroundColor: data.color,
              border: `1px solid ${data.border}`,
              borderRadius: "0.25rem",
              position: "absolute",
              transform: "translateY(-1px)",
              left: `calc(${data.value} - 0.656rem)`,
              zIndex: data.color === "#2F736E14" ? 1 : 0
            }}
          >
            <Typography
              sx={{
                width: "1.313rem",
                fontFamily: "Monrope, sans-serif",
                fontWeight: 700,
                fontSize: "0.625rem",
                color: "#B4B4B4",
                transform: "translateY(2.125rem)"
              }}
            >
              {data.label}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default BenchmarkingAttributesField;
import { useEffect, useState } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { CustomDropdown } from "@components";
import { ReactComponent as Cod } from "../../../../assets/icons/cod_logo.svg";

export interface Filter {
  key: string;
  label: string;
  values: { id: string; label: string; genre?: string }[];
}

const dummyFilters: Filter[] = [
  {
    key: "os",
    label: "OS",
    values: [
      { id: "ios", label: "iOS" },
      { id: "windows", label: "Windows" },
    ],
  },
  {
    key: "game",
    label: "game",
    values: [
      { id: "cod", label: "COD", genre: "First-person shooter" },
      { id: "rdr", label: "RDR",genre: "Adventure" },
    ],
  },
];


const BenchmarkingFilters = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  
  const [filters, setFilters] = useState<Filter[]>([]);
  const [selections, setSelections] = useState<Record<string, string>>({});

  useEffect(() => {
    setFilters(dummyFilters)
    const initialSelections = filters.reduce((acc, filter) => {
      acc[filter.key] =filter.values[0].id;
      return acc;
    }, {} as Record<string, string>);
    setSelections(initialSelections);
  },[filters])

  const handleFilterChange = (key: string, selectedValue: string) => {
    const updatedSelections = { ...selections, [key]: selectedValue };
    setSelections(updatedSelections);
  };

  const selectedGame = dummyFilters
    .find((filter) => filter.key === "game")
    ?.values.find((value) => value.id === selections["game"]);


  return (
    <Box
      sx={{
        padding: "0.8rem",
        backgroundColor: "white",
        borderRadius: "0.8rem",
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "0.75rem",
          paddingBottom: isMobile ? "0.5rem" : ""
        }}
      >
        <Cod/>
        <Box>
          <Typography
            sx={{
              color: "#162936",
              fontWeight: 700,
              fontSize: "1.5rem",
              lineHeight: "1.5rem",
              paddingBottom: "0.5rem"
            }}
          >
            BenchMarking : {selections["game"]?.toUpperCase()}
          </Typography>
          <Typography
            sx={{
              color: "#6C6C6C",
              fontWeight: 500,
              fontSize: "0.875rem",
              lineHeight: "1rem",
            }}
          >
            {selectedGame?.genre || "Unknown genre"}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: isMobile ? "column" : "row", gap: "0.8rem" }}>
        {filters.map((filter) => (
          <CustomDropdown
            key={filter.key}
            selectedOption={selections[filter.key]}
            dropdownOptions={filter.values}
            handleChange={(event) => handleFilterChange(filter.key, event.target.value)}
          />
        ))}
      </Box>
    </Box>
  );
}

export default BenchmarkingFilters